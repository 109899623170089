import React, {useState} from 'react'
import { Link, graphql, useStaticQuery } from 'gatsby'
//import Img from "gatsby-image"
import logoImage from '../images/logo3.svg'

//import './header.module.scss'
import headerStyles from './header.module.scss'

const Header = () => {
    const data = useStaticQuery(graphql`
        query {
            site {
                siteMetadata{
                    title
                }
            }
            file(relativePath: { eq: "images/logo.jpg" }) {
                childImageSharp {
                  # Specify a fixed image and fragment.
                  # The default width is 400 pixels
                  fluid(maxWidth: 400, maxHeight: 50) {
                    ...GatsbyImageSharpFluid
                  }
                }
            }
        }
    `)
    const [activeMenu, setActiveMenu] = useState(true);
    const activateSpMenu = () => setActiveMenu(!activeMenu);
    const spMenu = "";
    const spMenuActive = "";

    const line1 = "";
    const activeLine1 = "";
    const line2 = "";
    const activeLine2 = "";
    const line3 = "";
    const activeLine3 = "";

    return (
        <header className={headerStyles.header}>
            <Link to="/">
                <img src={logoImage}  className={headerStyles.logo} alt="Aiki Ishin-ryu logo"/>
            </Link>
            <div className={headerStyles.pcMenu}>
                <nav>
                    <ul>
                        <li>
                            <Link className={headerStyles.navItem} activeClassName={headerStyles.activeNavItem} to="/">Home</Link>
                        </li>
                        <li>
                            <Link className={headerStyles.navItem} activeClassName={headerStyles.activeNavItem} to="/about">About</Link>
                        </li>
                        <li>
                            <Link className={headerStyles.navItem} activeClassName={headerStyles.activeNavItem} to="/news">News</Link>
                        </li>
                        <li>
                            <Link className={headerStyles.navItem} activeClassName={headerStyles.activeNavItem} to="/contact">Contact</Link>
                        </li>
                    </ul>
                </nav>
            </div>
            <div className={headerStyles.menuBox} onClick={activateSpMenu}>
                <span></span>
                <svg width="100" height="100" viewBox="0 0 100 100">
                    <path className={activeMenu ? headerStyles.line1 : headerStyles.activeLine1} d="M 20,29.000046 H 80.000231 C 80.000231,29.000046 94.498839,28.817352 94.532987,66.711331 94.543142,77.980673 90.966081,81.670246 85.259173,81.668997 79.552261,81.667751 75.000211,74.999942 75.000211,74.999942 L 25.000021,25.000058" />
                    <path className={activeMenu ? headerStyles.line2 : headerStyles.activeLine2} d="M 20,50 H 80" />
                    <path className={activeMenu ? headerStyles.line3 : headerStyles.activeLine3} d="M 20,70.999954 H 80.000231 C 80.000231,70.999954 94.498839,71.182648 94.532987,33.288669 94.543142,22.019327 90.966081,18.329754 85.259173,18.331003 79.552261,18.332249 75.000211,25.000058 75.000211,25.000058 L 25.000021,74.999942" />
                </svg>
            </div>
            <div className={activeMenu ? headerStyles.spMenu : headerStyles.spMenuActive}>
                <ul>
                    <li>
                        <Link className={headerStyles.navItem} activeClassName={headerStyles.activeNavItem} to="/">Home</Link>
                    </li>
                    <li>
                        <Link className={headerStyles.navItem} activeClassName={headerStyles.activeNavItem} to="/about">About</Link>
                    </li>
                    <li>
                        <Link className={headerStyles.navItem} activeClassName={headerStyles.activeNavItem} to="/news">News</Link>
                    </li>
                    <li>
                        <Link className={headerStyles.navItem} activeClassName={headerStyles.activeNavItem} to="/contact">Contact</Link>
                    </li>
                </ul>
            </div>
        </header>
    )
}

export default Header