  
/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react";
import { Helmet } from "react-helmet";
import { useStaticQuery, graphql } from "gatsby";

export default (props) => {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          description
          title
          lang
        }
      }
    }
  `);

  // show pagetitle before site name in header
  const title = props.pagetitle
    ? `${props.pagetitle} | ${data.site.siteMetadata.title}`
    : data.site.siteMetadata.title;
  const description = props.pageDesc || data.site.siteMetadata.description;

  return (
    <Helmet>
      <html lang={data.site.siteMetadata.lang} />
      <title>{title}</title>
      <meta name="description" content={description} />
      <link rel="apple-touch-icon" href="icons/icon-48x48.png" sizes="48x48" />
      <link rel="apple-touch-icon" href="icons/icon-72x72.png" sizes="72x72" />
      <link rel="apple-touch-icon" href="icons/icon-96x96.png" sizes="96x96" />
      <link rel="apple-touch-icon" href="icons/icon-144x144.png" sizes="144x144" />
      <link rel="apple-touch-icon" href="icons/icon-192x192.png" sizes="192x192" />
      <link rel="apple-touch-icon" href="icons/icon-256x256.png" sizes="256x256" />
      <link rel="apple-touch-icon" href="icons/icon-384x384.png" sizes="384x384" />
      <link rel="apple-touch-icon" href="icons/icon-512x512.png" sizes="512x512" />
    </Helmet>
  );
};