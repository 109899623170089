import React from 'react'
import { graphql, useStaticQuery ,Link} from 'gatsby'

import logoImage from '../images/logo3.svg'
import footerStyles from './footer.module.scss'

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faFacebook,
    faInstagram,
    faYoutube,
  } from "@fortawesome/free-brands-svg-icons";

const Footer = () => {
    const data = useStaticQuery(graphql`
        query {
            site {
                siteMetadata{
                    author
                }
            }
        }
    `)
    return (
        <footer className={footerStyles.footer}>
            <div className={footerStyles.footerContainer}>
                <Link to="/">
                    <img src={logoImage} className={footerStyles.logo} alt="Aiki Ishin-ryu logo" />
                </Link>
                <div className={footerStyles.footerNav}>
                    <ul className={footerStyles.siteLink}>
                        <li>
                            <Link className={footerStyles.navItem} activeClassName={footerStyles.activeNavItem} to="/">Home</Link>
                        </li>
                        <li>
                            <Link className={footerStyles.navItem} activeClassName={footerStyles.activeNavItem} to="/about">About</Link>
                        </li>
                        <li>
                            <Link className={footerStyles.navItem} activeClassName={footerStyles.activeNavItem} to="/news">News</Link>
                        </li>
                        <li>
                            <Link className={footerStyles.navItem} activeClassName={footerStyles.activeNavItem} to="/contact">Contact</Link>
                        </li>
                        <li>
                            <Link className={footerStyles.navItem} activeClassName={footerStyles.activeNavItem} to="/privacy">Privacy Policy</Link>
                        </li>
                        <li>
                            <Link className={footerStyles.navItem} to="https://aikiishinryu.jp/">日本語</Link>
                        </li>
                        <li>
                            <Link className={footerStyles.navItem} to="https://ar.aikiishinryu.jp/">اَلْعَرَبِيَّةُ</Link>
                        </li>
                    </ul>
                    <ul className={footerStyles.sns}>
                        <li>
                            <a href="https://www.facebook.com/aikiishinryu" target="blank">
                                <FontAwesomeIcon
                                    icon={faFacebook}
                                    style={{ color: "#000" }}
                                />
                            </a>
                        </li>
                        <li>
                            <a href="https://www.instagram.com/aiki_ishinryu_jiujitsu/" target="blank">
                                <FontAwesomeIcon icon={faInstagram} style={{ color: "#000" }} />
                            </a>
                        </li>
                        <li>
                            <a href="https://www.youtube.com/channel/UC5L5ro8sDa7048WRFhJ4qTQ" target="blank">
                                <FontAwesomeIcon icon={faYoutube} style={{ color: "#000", fontSize: "0.85em" }} />
                            </a>
                        </li>
                    </ul>
                </div>
                
            </div>
            <div className={footerStyles.copyright}>
                <p>Copyright © 相氣一進流 2020. All rights Reserved.</p>
                <p>Built by <a href="https://github.com/TuckerSarge" target="blank">{data.site.siteMetadata.author}</a></p>
            </div>
        </footer>
    )
}

export default Footer